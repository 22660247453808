import React from "react";
import { SlideInLeft } from "../../Animations/slideInLeft";
import { SlideInRight } from "../../Animations/slideInRight";
import { SectionTitle } from "../../Shared/SectionTitle";
import "./manifiesto.scss";

export const Manifiesto: React.FC = () => {
  return (
    <section className="manifiesto" id="manifiesto">
      <div className="container">
        <SectionTitle title="Manifiesto" />
        <div className="container-grid">
          <div className="first">
            <SlideInLeft distance={30} duration={0.6}>
              <p>Creemos en la moda como medio de expresión.</p>
              <p>
                Queremos llevar a todos los diseñadores apasionados y con algo
                para decir a los principales escenarios del mundo. Facilitando
                el camino, modernizando los métodos y logrando que todo ese
                proceso sea no solo inspirador, sino también rentable.
                <br />
                Nuestra región tiene voz e identidad propia.
                <b>Tenemos mucho para decir: digámoslo fuerte.</b>
              </p>
            </SlideInLeft>
          </div>
          <div className="last">
            <SlideInRight distance={30} duration={0.6}>
              <img
                src="https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1652271701/We-Are/manifiesto_pktxgl.jpg"
                alt="Manifiesto"
                className="img-fluid"
              />
            </SlideInRight>
          </div>
        </div>
      </div>
    </section>
  );
};
