import React from "react";
import { SiteConfig } from "../../interfaces/site";
import { Hero } from "./Hero/hero.component";
import { Horizonte } from "./Horizonte/horizonte.component";
import { NosInvolucramos } from "./NosInvolucramos/nos-involucramos.component";
import { Convoca } from "./Convoca/convoca.component";
import { Facilitamos } from "./Facilitamos/facilitamos.component";
import { Vision } from "./Vision/vision.component";
import { Equipo } from "./Equipo/equipo.component";
import { Manifiesto } from "./Manifiesto/manifiesto.component";
import "./home.scss";

interface HomeProps {
  siteConfig: SiteConfig;
}

const HomePage: React.FC<HomeProps> = ({ siteConfig }) => {
  return (
    <div className="home-page">
      <Hero />
      <Horizonte />
      <NosInvolucramos />
      <Facilitamos />
      <Convoca />
      <Vision />
      <Equipo />
      <Manifiesto />
    </div>
  );
};

export default HomePage;
