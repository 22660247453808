import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React, { useState } from "react";
import { scrollToDiv } from "../../../services/utils";
import { SimpleWithSingsLogo } from "../Logo/SimpleWithSignsLogo/simple-with-signs-logo.component";
import "./header.scss";

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const goToManifiesto = (): void => {
    scrollToDiv("manifiesto", 1000, -70);
  };

  return (
    <header id="header" className="header">
      <div className="container">
        <div className="flex-row">
          <div className="logo">
            <Link to="/">
              <SimpleWithSingsLogo />
            </Link>
          </div>
          <div className="links">
            <AnchorLink
              to="/#manifiesto"
              title="Manifesto"
              className="link"
              stripHash
            />
            <Link to={"/contacto"} activeClassName="active" className="link">
              Inscribete
            </Link>
            <a
              href="https://api.whatsapp.com/send?phone=5492323621499&text=Hola,%20Estoy%20en%20la%20web%20de%20WAU,%20quiero%20más%20información"
              target={"_blank"}
              className="link"
            >
              Contacto
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};
