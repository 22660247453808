import React from "react";
import { SlideInDown } from "../../Animations/slideInDown";
import { SlideInLeft } from "../../Animations/slideInLeft";
import { SlideInRight } from "../../Animations/slideInRight";
import { SectionTitle } from "../../Shared/SectionTitle";
import "./nos-involucramos.scss";

interface NosInvolucramosProps {}

export const NosInvolucramos: React.FC<NosInvolucramosProps> = () => {
  return (
    <section className="nos-involucramos">
      <div className="container">
        <SectionTitle title="Nos involucramos" />
        <div className="projects container-grid">
          <div className="project first">
            <div className="imgs">
              <SlideInLeft distance={30} duration={0.6}>
                <img
                  src="https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1652222213/We-Are/Lupe-Gajardo-disen%CC%83o-de-Chile_jxphmg.jpg"
                  alt="Nos involucramos"
                />
              </SlideInLeft>
            </div>
            <SlideInLeft distance={30} duration={0.6}>
              <p>
                Desarrollamos y asistimos con soluciones probadas para que cada
                vez más marcas puedan acceder a mejores costos, servicios y
                mercados.
              </p>
            </SlideInLeft>
          </div>
          <div className="project last">
            <SlideInRight distance={30} duration={0.6}>
              <p>
                Ponemos a disposición el conocimiento de una red de expertos que
                conforma un equipo con experiencia en cada área de la Industria
                de la moda.
              </p>
            </SlideInRight>
            <SlideInDown distance={30} duration={0.6}>
              <img
                src="https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1652222216/We-Are/nos-involucramos_ojlmp5.jpg"
                alt="Nos involucramos"
                className="img-fluid"
              />
            </SlideInDown>
          </div>
        </div>
      </div>
    </section>
  );
};
