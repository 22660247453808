import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

type OrchestrationContainerProps = {
  children: any;
  classNameContainer?: any;
  classNameItems?: any;
  animationType?: string;
  delay?: number;
  delayItems?: number;
};

export const OrchestrationContainer: React.FC<OrchestrationContainerProps> = ({
  children,
  classNameContainer,
  classNameItems,
  animationType,
  delay = 0.1,
  delayItems = 0.3,
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const container = {
    hidden: {
      opacity: 1,
    },
    visible: {
      opacity: 1,
      transition: {
        delay,
        // when: "beforeChildren",
        ease: "easeIn",
        staggerChildren: delayItems,
      },
    },
  };

  let item;

  if (animationType) {
    if (animationType === "left-to-right") {
      item = {
        hidden: { x: -100, opacity: 0 },
        visible: {
          x: 0,
          opacity: 1,
        },
      };
    }
  } else {
    item = {
      hidden: { y: 50, opacity: 0 },
      visible: {
        y: 0,
        opacity: 1,
      },
    };
  }

  return (
    <motion.ul
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={container}
      className={classNameContainer}
    >
      {children &&
        children.map((c, i) => (
          <motion.li key={i} variants={item} className={classNameItems}>
            {c}
          </motion.li>
        ))}
    </motion.ul>
  );
};
