import React from "react";
import { OrchestrationContainer } from "../../Animations/orchestrationContainer";
import { SlideInDown } from "../../Animations/slideInDown";
import { SimpleLogo } from "../../Shared/Logo/SimpleLogo/simple-logo.component";
import { VerboseLogo } from "../../Shared/Logo/VerboseLogo/verbose-logo.component";
import { SectionTitle } from "../../Shared/SectionTitle";
import "./facilitamos.scss";

const list = [
  {
    number: "01",
    title: "Inversión",
    description:
      "Acercamos instrumentos de financiación para desarrollar marcas y productos con desembolsos que pueden tener un horizonte de hasta 3 años.",
    overlayColor: "green",
  },
  {
    number: "02",
    title: "Co-creación",
    description:
      "Comprometemos importantes recursos de mentoría y unimos a equipos creativos para co-crear y diseñar las colecciones. Así, aprovechamos la sabiduría y la diversidad de nuestra comunidad de talentos.",
    overlayColor: "light-blue-2",
  },
  {
    number: "03",
    title: "Producción",
    description:
      "Garantizamos el desarrollo de las colecciones de punta a punta. Resolvemos todas las actividades necesarias para el desarrollo de colecciones compartiendo recursos a escala optimizando costos y calidad.",
    overlayColor: "orange",
  },
  {
    number: "04",
    title: "Co-Work",
    description:
      "Ofrecemos un punto de encuentro para todos. Un espacio de trabajo en donde toda la comunidad WAU se encuentra y accede a dispositivos y herramientas necesarias para impulsar sus proyectos.",
    overlayColor: "purple",
  },
  {
    number: "05",
    title: "Dirección de Arte",
    description:
      "Producimos contenido para comunicar ideas únicas. Potenciamos la exposición de cada una de las marcas, llegando a mayores audiencias respetando las identidades.",
    overlayColor: "yellow",
  },
  {
    number: "06",
    title: "Amplificación<br />internacional",
    description:
      "Transformamos las marcas locales en internacionales. Expandimos el negocio llevándolas a nuevos países como parte de una propuesta integrada por un conjunto de marcas bajo el endorsement de la marca WAU, a través de nuestra red global de socios comerciales.",
    overlayColor: "pink",
  },
  {
    number: "07",
    title: "Formación",
    description:
      "Conocimiento potenciado-Trabajamos con importantes instituciones del ámbito académico para brindar herramientas de formación empresarial.",
    overlayColor: "aqua",
  },
  {
    number: "08",
    title: "Inversión",
    description:
      "Potenciamos el conocimiento de la red. Trabajamos con importantes instituciones del ámbito académico para brindar herramientas de formación empresaria.",
    logo: true,
    overlayColor: "light-blue",
  },
];

export const Facilitamos: React.FC = () => {
  return (
    <section className="facilitamos">
      <div className="container">
        <SectionTitle title="Facilitamos" />
        <SlideInDown distance={30} duration={0.6}>
          {/* <div className="facilitamos-list"> */}
          <OrchestrationContainer
            classNameContainer={"facilitamos-list"}
            classNameItems={"facilitamos-item"}
            delay={0.1}
            delayItems={0.1}
          >
            {list.map((item, i) => (
              <React.Fragment key={i}>
                {item.logo ? (
                  <>
                    <div className="logo">
                      <SimpleLogo />
                    </div>

                    <div className={`overlay ${item.overlayColor}`}>
                      <div className="logo">
                        <VerboseLogo />
                      </div>
                    </div>
                  </>
                ) : null}
                {!item.logo ? (
                  <>
                    <div className="number">{item.number}</div>
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                    />

                    <div className={`overlay ${item.overlayColor}`}>
                      <p>{item.description}</p>
                    </div>
                  </>
                ) : null}
              </React.Fragment>
            ))}
          </OrchestrationContainer>
          {/* </div> */}
        </SlideInDown>
      </div>
    </section>
  );
};
