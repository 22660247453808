import React, { useState } from "react";
import "./PassPage.scss";

type PassProps = {
  canEnter: (value: boolean) => void;
};

const Pass: React.FC<PassProps> = ({ canEnter }) => {
  const [show, setShow] = useState<boolean>(false);
  const [value, setValue] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    const parsedValue = value ? value.toLowerCase().trim() : "";
    canEnter(process.env.GATSBY_PASS === parsedValue);
  };

  return (
    <div className="pass-page">
      <div className="pass-wrap">
        <img src="../logo-white.svg" className="logo" alt="Codevelopers" />

        <form onSubmit={(e) => onSubmit(e)}>
          <div className={"input-container " + (show ? "light" : "false")}>
            <div className="circle"></div>
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className="fas fa-lock"
              style={{ width: "1rem" }}
            >
              <path
                fill="currentColor"
                d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
              ></path>
            </svg>
            <input
              type={show ? "text" : "password"}
              onChange={(event) => setValue(event.target.value)}
              required
            />
            <img
              className="fas"
              src={show ? "../eye.svg" : "../eye-slash.svg"}
              width="20px"
              onClick={() => setShow(!show)}
            />
          </div>
          <div className="button-wrap">
            <button className="button" type="submit">
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <span>ENTRAR</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Pass;
