import React, { useEffect, useState } from "react";
import Seo from "../../Shared/SEO/Seo";
import { getSeo } from "../../../services/SEO";
import { SiteConfig } from "../../../interfaces/site";
import Footer from "../Footer";
import Pass from "../Pass";
import { Header } from "../Header/header.component";

interface LayoutProps {
  children: any;
  siteConfig: SiteConfig;
  seoPage: any;
}

const Layout: React.FC<LayoutProps> = ({ children, siteConfig, seoPage }) => {
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    setEnabled(
      typeof window !== "undefined"
        ? localStorage.getItem("canEnter") === process.env.GATSBY_ENABLED
        : false
    );
  }, []);

  const letItIn = (canEnter) => {
    setEnabled(canEnter);
    if (typeof window !== "undefined" && canEnter) {
      localStorage.setItem("canEnter", process.env.GATSBY_ENABLED);
    }
  };

  const seoContent = getSeo(siteConfig, seoPage);

  return (
    <React.Fragment>
      {!enabled && (
        <div>
          <Pass canEnter={(canEnter) => letItIn(canEnter)} />
        </div>
      )}

      {enabled && (
        <>
          <Seo content={seoContent} language={siteConfig.lang} />
          <Header />
          <main role="main">
            <div>{children}</div>
          </main>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
};

export default Layout;
