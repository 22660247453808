import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";
import { scrollToDiv } from "../../../services/utils";
import { FullLogo } from "../Logo/FullLogo/full-logo.component";
import "./footer.scss";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <footer id="footer" className="footer">
      <div className="container container-grid">
        <div className="logo">
          <FullLogo />
        </div>

        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <AnchorLink
              to="/#manifiesto"
              title="Manifesto"
              className="stripped"
              stripHash
            />
          </li>
          <li>
            <Link to={"/contacto"} activeClassName="active" className="link">
              Inscribete
            </Link>
          </li>
        </ul>

        <div className="contact">
          <a
            href="https://api.whatsapp.com/send?phone=5492323621499&text=Hola,%20Estoy%20en%20la%20web%20de%20WAU,%20quiero%20más%20información"
            target={"_blank"}
            className="link"
          >
            Contacto
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
