import React from "react";

interface SimpleLogoProps {
  color?: string;
}

export const SimpleLogo: React.FC<SimpleLogoProps> = ({ color = "#000" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 138 45">
      <path
        fill={color}
        stroke="#fff"
        d="M50 1H50L50 1 45 18 45 18 45 18C44 23 43 28 42 31 41 26 40 22 39 18L39 18 35 1 35 1H34 26 26L26 1 21 18 21 18 21 18C21 18 21 18 21 18 20 22 19 26 18 31 18 28 17 24 15 18L11 1 11 1H10 2 1L2 1 13 43 13 43H14 22 23L23 43 27 25 27 25 27 25C28 21 29 17 30 13 31 16 32 20 33 25L33 25 33 25 37 43 38 43H38 47 47L47 43 59 1 59 1H58 50ZM88 43 88 43H88 96 97L97 42 81 1 81 1H80 71 71L71 1 55 42 55 43H55 64 64L64 43 67 34H84L88 43ZM82 26H70L71 22C73 18 74 14 76 10 78 15 79 19 80 22L82 26ZM101 1H101V1 27C101 32 103 36 106 39 109 42 113 44 119 44 125 44 129 42 132 39 135 36 137 32 137 27V1 1H136 128 128V1 27C128 30 127 32 126 34 124 35 122 36 119 36 116 36 114 35 112 34 111 32 110 30 110 27V1 1H109 101Z"
      />
    </svg>
  );
};
