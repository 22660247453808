import React from "react";
import { graphql } from "gatsby";
import HomePage from "../../components/Home";
import Layout from "../../components/Shared/Layout";

const HomeTemplate = ({ data }) => {
  const { siteConfig } = data;
  const { seo } = data.home.tabs;
  seo.slug = { current: "" };

  return (
    <Layout siteConfig={siteConfig} seoPage={seo}>
      <HomePage siteConfig={siteConfig} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query HomePageQuery {
    siteConfig: sanitySiteConfig {
      lang
      title
      description
      url
      mainImage {
        asset {
          url
        }
      }
    }
    home: sanityHome {
      title
      slug {
        current
      }
      tabs {
        seo {
          meta_title
          meta_description
          article
          seo_image {
            asset {
              url
            }
          }
          includeInSitemap
        }
      }
    }
  }
`;

export default HomeTemplate;
