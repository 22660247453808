import React, { useState } from "react";
import { SlideInDown } from "../../Animations/slideInDown";
import { SectionTitle } from "../../Shared/SectionTitle";
import "./vision.scss";

const list = [
  {
    title: "Diseño emprendedor",
    description:
      "Ofrecemos moda emergente y emprendedora a un precio asequible.",
  },
  {
    title: "Experiencia de compra Disruptiva",
    description:
      "Tanto nuestros locales físicos como nuestras plataformas de compra on-line sorprenderán. Nuestro CRM permitirá conocer en detalle a cada consumidor, para ofrecerle la propuesta que mejor lo conquiste.",
  },
  {
    title: "Sustentabilidad",
    description:
      "Acercamos soluciones sustentables a las marcas, que ayuden a evolucionar la industria hacia un compromiso de respeto con el medio ambiente.",
  },
  {
    title: "Cadena de Abastecimiento World Class",
    description:
      "Digitalizamos y optimizamos nuestra cadena de valor apalancándonos en plataformas blockchain, llevando al máximo la eficiencia y la transparencia. Además, utilizamos Big Data y Machine learning para optimizar y mejorar los procesos de stock.",
  },
  {
    title: "People first | WAU Crew",
    description:
      "Formamos una cultura colaborativa y emprendedora que crea más valor para más consumidores.",
  },
];

export const Vision: React.FC = () => {
  const [indexSelected, setIndexSelected] = useState<number | null>(-1);
  const openDescription = (index: number): void => {
    if (index === indexSelected) {
      setIndexSelected(-1);
      return;
    }
    setIndexSelected(index);
  };

  return (
    <section className="vision">
      <div className="container">
        <SectionTitle title="Nuestra visión" />
        <div className="container-grid">
          <div className="left-content">
            <ul>
              {list.map((item, i) => {
                return (
                  <li
                    key={item.title}
                    className={
                      indexSelected !== -1
                        ? indexSelected === i
                          ? "selected"
                          : null
                        : "init-state"
                    }
                  >
                    <div
                      className="title-container"
                      onClick={() => openDescription(i)}
                    >
                      <div className="symbol">
                        <span className="plus">+</span>
                        <span className="minus">-</span>
                      </div>
                      <div className="title">{item.title}</div>
                    </div>
                    <div className="description">{item.description}</div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="right-content">
            <div className="imgs">
              <SlideInDown distance={30} duration={0.6}>
                <img
                  src="https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1652225997/We-Are/vision_yhhang.png"
                  alt="Vision"
                  className="img-fluid"
                />
              </SlideInDown>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
