import { useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import "./section-title.scss";

interface SectionTitleProps {
  title: string;
  animate?: boolean;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  animate = true,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      if (animate) {
        setVisible(true);
      }
    }
  }, [controls, inView]);

  return (
    <h2 className={`section-title ${visible ? "small" : ""}`} ref={ref}>
      <div className="wau">WAU</div>
      <div className="separator" />
      <span>{title}</span>
    </h2>
  );
};
