import React from "react";
import { SlideInLeft } from "../../Animations/slideInLeft";
import { SlideInRight } from "../../Animations/slideInRight";
import { SectionTitle } from "../../Shared/SectionTitle";
import "./equipo.scss";

export const Equipo: React.FC = () => {
  return (
    <section className="equipo">
      <div className="container">
        <SectionTitle title="Equipo" />
        <div className="container-grid">
          <div className="first">
            <SlideInLeft distance={30} duration={0.6}>
              <img
                src="https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1652271171/We-Are/equipo_zaq4gm.jpg"
                alt="Equipo"
                className="img-fluid"
              />
            </SlideInLeft>
          </div>
          <div className="last">
            <SlideInRight distance={30} duration={0.6}>
              <p>
                Somos una compañía que sostiene a la colaboración y creatividad
                como centro de su modelo y a una estrategia basada en la
                optimización e innovación constante, en la búsqueda de la
                sostenibilidad y escalabilidad de un completo ecosistema de
                canales y partners.
              </p>
              <p>
                Como grupo de líderes que abarca a los diferentes segmentos de
                la industria, nos mantenemos a la vanguardia de las mejores
                prácticas, estamos constantemente probando, aprendiendo,
                implementando y compartiendo.
              </p>
            </SlideInRight>
          </div>
        </div>
      </div>
    </section>
  );
};
