import { Link } from "gatsby";
import React from "react";
import { SlideInDown } from "../../Animations/slideInDown";
import { SlideInLeft } from "../../Animations/slideInLeft";
import { SlideInRight } from "../../Animations/slideInRight";
import { SectionTitle } from "../../Shared/SectionTitle";
import "./convoca.scss";

interface ConvocaProps {}

export const Convoca: React.FC<ConvocaProps> = () => {
  return (
    <section className="convoca">
      <div className="container">
        <SectionTitle title="Te convocamos" />
        <div className="projects container-grid">
          <div className="project first">
            <SlideInDown distance={30} duration={0.6}>
              <img
                src="https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1652223888/We-Are/convoca-left_oudloo.png"
                alt="Convoca"
                className="img-fluid"
              />
            </SlideInDown>
            <SlideInLeft distance={30} duration={0.6}>
              <h3>
                Estamos en la búsqueda de marcas
                <br />
                que quieran trascender.
              </h3>
            </SlideInLeft>
            <SlideInDown distance={30} duration={0.6}>
              <p>
                Convocamos a marcas, diseñadores, <br />
                directores creativos, fabricantes, y todo <br />
                aquel que quiera crecer y evolucionar de <br />
                la mano de un equipo de expertos.
              </p>
            </SlideInDown>
          </div>
          <div className="project last">
            <SlideInRight distance={30} duration={0.6}>
              <img
                src="https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1652223893/We-Are/convoca-right_vs5wuv.png"
                alt="Convoca"
                className="img-fluid"
              />
            </SlideInRight>

            <SlideInDown distance={30} duration={0.6}>
              <Link to="/contacto" className="button">
                Co-crea con nosotros
              </Link>
            </SlideInDown>
          </div>
        </div>
      </div>
    </section>
  );
};
