import React from "react";
import "./hero.scss";

interface HomeProps {}

export const Hero: React.FC<HomeProps> = () => {
  return (
    <div className="home-hero">
      <div className="container">
        <div className="title-content">
          <h1>
            We
            <br />
            Are
            <br />
            United
          </h1>
          <h3>Desarrollamos diseñadores emergentes</h3>
          <p>
            Brindamos recursos, procesos y conocimientos especializados <br />
            para destrabar el potencial de las marcas en Latinoamérica, <br />
            permitiéndoles crecer aceleradamente en el mundo.
          </p>
        </div>
      </div>
    </div>
  );
};
