import React from "react";

interface SimpleWithSingsLogoProps {
  color?: string;
}

export const SimpleWithSingsLogo: React.FC<SimpleWithSingsLogoProps> = ({
  color = "#fff",
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 185 58">
      <path
        fill={color}
        d="M173 37H179L181 7H171L173 37ZM172 42C171 43 171 44 171 45 171 47 171 48 172 49 173 50 174 50 176 50 177 50 179 50 180 49 181 48 181 47 181 45 181 44 181 43 180 42 179 41 177 40 176 40 174 40 173 41 172 42ZM14 21 7 21 6 51 15 51 14 21ZM14 16C15 15 16 13 16 12 16 10 15 9 14 8 13 7 12 7 10 7 9 7 7 7 6 8 5 9 5 10 5 12 5 13 5 15 6 16 7 17 9 17 10 17 12 17 13 17 14 16Z"
      />
      <path
        fill={color}
        stroke={color}
        d="M72 8H72L72 8 67 25 67 25 67 25C66 31 65 35 64 38 63 33 62 29 61 25L61 25 57 8 57 8H56 48 48L48 8 43 25 43 25 43 25C43 25 43 25 43 26 42 29 41 34 40 38 40 35 39 31 37 25L33 8 33 8H32 24 23L24 8 35 50 35 50H36 44 45L45 50 49 33 49 33 49 33C50 29 51 24 52 20 53 23 54 27 55 33L55 33 55 33 59 50 60 50H60 69 69L69 50 81 8 81 8H80 72ZM110 50 110 50H110 118 119L119 49 103 8 103 8H102 93 93L93 8 77 49 77 50H77 86 86L86 50 89 41H106L110 50ZM104 33H92L93 30C95 25 96 21 98 17 100 22 101 26 102 30L104 33ZM123 8H123V8 34C123 39 125 43 128 46 131 49 135 51 141 51 147 51 151 50 154 47 157 44 159 39 159 34V8 8H158 150 150V8 34C150 37 149 39 148 41 146 42 144 43 141 43 138 43 136 42 134 41 133 39 132 37 132 34V8 8H131 123Z"
      />
    </svg>
  );
};
