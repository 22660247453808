export function getSeo(generalSeo, pageSeo) {
  const title = generalSeo.title;
  const overrideTitle = pageSeo.meta_title;
  const description = pageSeo.meta_description || generalSeo.description;
  const image = pageSeo.seo_image
    ? pageSeo.seo_image?.asset?.url
    : generalSeo.mainImage?.asset?.url;
  const baseUrl = generalSeo.url;
  const slug = pageSeo.slug.current;
  const canonical = slug ? baseUrl + "/" + slug : baseUrl;

  return {
    title,
    overrideTitle,
    description,
    image,
    article: pageSeo.article,
    url: canonical,
    canonical,
  };
}
