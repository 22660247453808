import React from "react";
import { OrchestrationContainer } from "../../Animations/orchestrationContainer";
import { SectionTitle } from "../../Shared/SectionTitle";
import "./horizonte.scss";

interface HorizonteProps {}

export const Horizonte: React.FC<HorizonteProps> = () => {
  return (
    <section className="horizonte">
      <div className="container">
        <SectionTitle title="Te impulsamos" />

        <OrchestrationContainer
          classNameContainer={"items"}
          classNameItems={"item"}
        >
          <div className="item">
            <div className="number">01</div>
            Seleccionamos marcas
            <br />
            de alto potencial
          </div>
          <div className="item">
            <div className="number">02</div>
            Proveemos todos los
            <br />
            servicios operativos
          </div>
          <div className="item">
            <div className="number">03</div>
            Impulsamos comercialización
            <br />
            en distintos países
          </div>
        </OrchestrationContainer>
      </div>
    </section>
  );
};
